body {
    background-color: #f5f7fa !important;
}

.circles_floating {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

@keyframes animate {
    0% {
        opacity: 1;
        border-radius: 0;
        transform: translateY(0) rotate(0deg);
    }

    100% {
        opacity: 0;
        border-radius: 50%;
        transform: translateY(400px) rotate(720deg);

    }
}

.circles_floating div {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    top: -150px;
    animation: animate 20s linear infinite;
}

.circles_floating div:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;

}

.circles_floating div:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;


}

.circles_floating div:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;


}

.circles_floating div:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;


}

.circles_floating div:nth-child(5) {
    left: 65%;
    width: 25px;
    height: 25px;
    animation-delay: 0s;



}

.circles_floating div:nth-child(6) {
    left: 75%;
    width: 40px;
    height: 40px;
    animation-delay: 3s;



}

.circles_floating div:nth-child(7) {
    left: 35%;
    width: 80px;
    height: 80px;
    animation-delay: 7s;


}

.circles_floating div:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;


}

.circles_floating div:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;


}

.circles_floating div:nth-child(10) {
    left: 85%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    animation-duration: 11s;


}

.circles_floating div:nth-child(11) {
    left: 5%;
    width: 25px;
    height: 25px;
    animation-delay: 0s;
    animation-duration: 11s;


}

.circles_floating div:nth-child(12) {
    left: 0%;
    width: 40px;
    height: 40px;
    animation-delay: 2s;
    animation-duration: 16s;


}

.circles_floating div:nth-child(13) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 1s;
    animation-duration: 11s;


}

.circles_floating div:nth-child(11) {
    left: 15%;
    width: 25px;
    height: 25px;
    animation-delay: 7s;
    animation-duration: 11s;


}

.sidebar_overflow {
    overflow: auto !important;
}

.navbar-brand-box {
    background-color: rgb(255, 255, 255) !important;
    width: 100%;
    padding: 0 !important;
    object-fit: cover;
}

.errorMessage {
    color: red;
}

.card_dash {
    font-size: 12px !important;
}

.tt {
    text-align: right;
}

select:required:invalid {
    /* color: aqua; */
}

option[value=""][disabled] {
    /* color: rgb(218, 138, 35); */
}

option {
    /* color: blue; */
}

.project_filter {
    font-size: 15px;
}

.form_divide hr {
    /* width: 80% !important; */
    margin-bottom: 20px;
}

.max_char {
    font-size: 10px !important;
    color: rgb(139, 138, 135);
    word-break: break-all;
}

.required_field {
    color: rgb(245, 10, 10);
}

.unique_id {
    width: 20% !important;
    margin-left: 5px;
    border: 1px solid rgb(138, 134, 134);
    /* padding: 1px; */
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;

}

.client_active_btn {
    gap: 10px;
    align-items: center;
    /* margin-bottom: 12px; */
}

.client_active_btn .search {

    text-align: right;
    position: absolute;
    justify-content: end;
    align-items: end;
    right: 30px;
    width: 280px !important;
}
.projectdetail_active_btn{
    gap: 10px;
}
.projectdetail_active_btn .search {

    text-align: right;
    position: absolute;
    justify-content: end;
    align-items: end;
    right: 30px;
    gap: 10px;
    /* width: 280px !important; */
}

.table_client {
    /* text-align: center !important; */
    vertical-align: middle !important;
}

.active_btn {
    color: rgba(var(--vz-success-rgb), var(--vz-bg-opacity)) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
}

.inactive_btn {
    color: rgba(var(--vz-warning-rgb), var(--vz-bg-opacity)) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
}

.closed_btn {
    color: rgba(var(--vz-danger-rgb), var(--vz-bg-opacity)) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
}

.Invoiced_btn {

    background-color: rgb(174, 0, 255);
    color: rgb(174, 0, 255) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.add_qustion {
    width: 100px;
}

.container-fluid .btn {
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important; */
    /* border: 1px solid #cbd0dd !important; */
}

.card {
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important; */
    border: 1px solid rgba(203, 208, 221, 0.54) !important;
    border-radius: 0.5rem !important;
}

.report .form-check {
    display: block;
    min-height: 1.21875rem;
    padding-left: 5.600000000000001em;
    margin-bottom: 0.125rem;
    margin-top: 25px;
}

.report_btn {
    margin-top: 20px;
}

.report_btn .btn {
    width: 100px;
    margin-bottom: 5px;
}

.page_size_support {
    display: flex;
}

.page_size_support .form-select {
    width: 50%;
    height: 32px;
    margin-left: 5px;
    padding-left: 7px;
    padding-bottom: 8px;
}

.mt-8_rec {
    margin-top: 55px;
}

.fs-8_profile {
    font-size: 34px;
}

.user-name-text-p {
    color: white;
}

.user-name-sub-text-p {
    color: rgb(170, 160, 160) !important;
    font-weight: 600;
}

.country_allowed {
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: 500;
    color: #303d67;
}

.optionListContainer_div .optionListContainer {
    transform: ease;
    overflow: auto !important;
    z-index: 99;
    height: auto !important;
    /* height: 90px !important; */
}

/* user add css */
.card_user {
    background-color: #f3f3f9 !important;

    
}

.card_user .search {
    width: 40%;
}

.user_sec .user_btn .btn {
    width: 80px;
    border-radius:5px;
    box-shadow: none !important;
    border: 1px solid rgb(8, 8, 8);
}

.user_sec p {
    margin-bottom: 3px;
    margin-top: 0px !important;
}
.module_user p{
    cursor: pointer !important;
}
.module_user p:hover{
    cursor: pointer !important;
    color:#303d67 !important ;
}
.btn-light-module{
    background-color: #ffffff;
    border: none !important;
}
.btn-light-module:hover{
    color:#303d67 !important ;
}
.btn-light-module:active{
    color:#303d67 !important ;
}
.btn-light-module::after{
    color:#303d67 !important ;
}

.user_sec span {
    font-size: 13px;
    color: #4a4e4eb4;
    font-weight: 500;
}
.lower_div{
    background-color: #f3f3f9;
}
.scrool {
    overflow-y: auto !important;
    height: calc(100vh - 140px);
    /* padding-bottom: 40px; */
}

.scrool {

    scrollbar-width: 3px !important;
    /* Firefox */
}
/* width */
::-webkit-scrollbar {
    width: 4px;
}

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cacdd7;
    border-radius: 10px;
}
    
.project_detail_topsec{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.project_detail_topsec h1{
    font-size: 24px;
}
.project_detail_topsec .active_btn{
    color: rgba(var(--vz-success-rgb), var(--vz-bg-opacity)) !important;
    width: 15px;
    height: 15px;
    padding: 0px !important;
}

.padTop {
    padding: 5px 0;
    font-size: 12.5px;
    color: #403e3e;
}
.fontBold {
    font-weight: 700;
    font-size: 13px;
    color: #403e3e;
    letter-spacing: 0;
}
.pull-right {
    float: right;
}

.small-input{
    max-width: 40px;
    max-height: 18px;
}

.notesWrapper{
    background-color: #eeedff;
    padding: 10px;
    box-shadow: 1px 1px 5px #cbcbcb;
}

.btn-color{
    background-color: rgba(0, 128, 0, 0.71);
    position: relative;
    display: inline-block;
    color: white;
    border-radius: 0px 5px 5px 0px;

    /* height: 150px; */
    /* width: 250px; */

}
.btn-color:hover{
    background-color: green;
    color: white;
}
/* .btn-color::before {
    content: "";
    position: absolute;
    top: 35%;
    left: -6px;
    width: 10px;
    height: 10px;
    background-color: green;
    
    border-radius: 50%;
   



} */
.btn-white{
    /* border: 1px solid rgb(90, 88, 88) !important; */
    border-radius: 5px 0px 0px 5px;
    background-color: rgb(255, 255, 255);
}
.btn-color1{
    background-color: #0ab39cac;
    position: relative;
    display: inline-block;
    color: white;
    border-radius: 0px 5px 5px 0px;

    /* height: 150px; */
    /* width: 250px; */

}
.btn-color1:hover{
    background-color: #0ab39c;
    color: white;
}
/* .btn-color1::before {
    content: "";
    position: absolute;
    top: 35%;
    left: -6px;
    width: 10px;
    height: 10px;
    background-color: #0ab39c;
    
    border-radius: 50%;
   



} */

.btn-color2{
    background-color: #f7b84bd9;
    position: relative;
    display: inline-block;
    color: white;
    border-radius: 0px 5px 5px 0px;

    /* height: 150px; */
    /* width: 250px; */

}
.btn-color2:hover{
    background-color: #f7b84b;
    color: white;
}
/* .btn-color2::before {
    content: "";
    position: absolute;
    top: 35%;
    left: -6px;
    width: 10px;
    height: 10px;
    background-color: #f7b84b;
    
    border-radius: 50%;
   



} */

.btn-color3{
    background-color: rgba(174, 0, 255, 0.715);
    position: relative;
    display: inline-block;
    color: white;
    border-radius: 0px 5px 5px 0px;

    /* height: 150px; */
    /* width: 250px; */

}
.btn-color3:hover{
    background-color: rgb(174, 0, 255);
    color: white;
}
/* .btn-color3::before {
    content: "";
    position: absolute;
    top: 35%;
    left: -6px;
    width: 10px;
    height: 10px;
    background-color: rgb(174, 0, 255);
    
    border-radius: 50%;
   



} */

.btn-color4{
    background-color: #f06448cf;
    position: relative;
    display: inline-block;
    color: white;
    border-radius: 0px 5px 5px 0px;

    /* height: 150px; */
    /* width: 250px; */

}
.btn-color4:hover{
    background-color: #f06548;
    color: white;
}
/* .btn-color4::before {
    content: "";
    position: absolute;
    top: 35%;
    left: -6px;
    width: 10px;
    height: 10px;
    background-color: #f06548;
    
    border-radius: 50%;
   



} */

.btn:hover{
    /* background-color: #ebecf3 !important; */
}

.projectdetail_active_btn .btn:hover{
    background-color: rgb(180, 187, 180) !important;
    color: #242323 !important;
}
.projectdetail_active_btn .outline_desh{
    border-bottom : 2px solid #ff8800 !important;
}
.projectdetail_active_btn  .tabs_ui .btn{
    /* border-bottom : 4px solid #ff8800 !important; */
    border-radius: 2px !important;
    box-shadow: none !important;
    border-top: 1px solid rgb(223, 216, 216)  !important;
    border-left: 1px solid rgb(223, 216, 216)  !important;
    border-right: 1px solid rgb(223, 216, 216)  !important;


}
.projectdetail_active_btn{
    height: 39px;
}
.prelabel label{
    width: 420px;
}
.table_redirect{
    height: 450px !important;
    overflow: auto;
}
.mt-2_btn{
    margin-top: 20px !important;
}
.formcheckedbg:checked{
    background-color: #7180b1 !important;
}
.quotainput{
    width: 40px !important;
padding: 1px !important;
}
.quota_dis{
    text-align: center;
}
.autocompleteUi textarea{
    resize: none !important;
    width: 485px !important;
    height: 35px !important;
}
.autocompleteUi textarea:focus{
    border:1px solid #303d67 !important;
}
.autocompleteUi .react-autocomplete-input{
    top: 65px !important;
   
}
 .react-autocomplete-input li.active{
    background-color: #405189 !important;
}
.bggray{
    background-color: #cbcbcb;
}
.statistic-tabs{
    background-color: #405189;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    margin-bottom: 20PX;

}
.statistic-tabs h1{
    font-size: 10px;
    color: white;
    font-weight: 300;
    margin-bottom: 0;
}
.statistic-tabs h2{
    font-size: 12px;
    color: white;
    font-weight: 500;
    margin-bottom: 0;
    align-items: end;
    text-align: right;
}
.statistic-tabs i{
    font-size: 22px;
}

.table-scroll-fixed .table thead th {
    position: sticky;
z-index:  99;
background-color: #ff7530 !important;
}
.table-scroll-fixed .table tbody td {
    position: sticky;
z-index:  99;
background-color: #ffffff !important;
}

.table-scroll-fixed thead th:nth-child(1), .table-scroll-fixed thead th:nth-child(2), .table-scroll-fixed thead th:nth-child(3), .table-scroll-fixed thead th:nth-child(4) {
    z-index: 999 !important;
}
.table-scroll-fixed tbody td:nth-child(1), .table-scroll-fixed thead th:nth-child(1) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    left: 0px;
    z-index: 999 !important;
    
}
.table-scroll-fixed tbody td:nth-child(2), .table-scroll-fixed thead th:nth-child(2) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    left: 50px;
    z-index: 999 !important;
}
.table-scroll-fixed tbody td:nth-child(3), .table-scroll-fixed thead th:nth-child(3) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    left: 150px;
    z-index: 999 !important;
}
.table-scroll-fixed tbody td:nth-child(4), .table-scroll-fixed thead th:nth-child(4) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    left: 270px;
    z-index: 999 !important;
}

.table thead th {
    white-space: nowrap;
}

.css-1h51icj-MuiAutocomplete-root {
    width: 500px !important;
}

.css-feqhe6 {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    vertical-align: top;
    width: 100%;
    min-width: 425px !important;
}

.select2-container .select2-selection--single {
    height: 34px !important;
  }
  
  .select2-container--default .select2-selection--single {
    border: 1px solid #ccc !important;
    border-radius: 0px !important;
  }

  .bg-blue-circle{
    display: block;
    float: left;
    background-color: #303d67;
    border-radius: 50%;
    color: #fff;
    font-weight: bolder;
    margin-right: 5px;
    width: 22px;
    height: 22px;
    padding: 2px;
    font-size: 11px;
    text-align: center;
  }
.rmPL{
    padding-left: 20px !important;
}

.btn-primary-1 {
    background-color: white;
    color: #ff7530;
    font-family: 'poppins';
    font-weight: 500;
}

.btn-primary-1:hover {
    background-color: #e3e6ed;
    color: #ff7530;
}

.btn-primary-search {
    background-color: #ff7530;
    color: white;
    font-family: 'poppins';
    font-weight: 500;
}

.btn-primary-search:hover {
    background-color: #fd8548;
    color: white;
}

.btn-primary-2 {
    background-color: #fcd116;
    color: white;
}

.btn-primary-2:hover {
    background-color: #fcd216e8;
    color: white;
}

.bg-primary-1 {
    background-color: #ff7530;
}
